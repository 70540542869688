import React, { useRef } from "react"

import styled from "styled-components"
import {
  useTransition,
  useTrail,
  useChain,
  animated,
  config,
} from "react-spring"

import { NavLink } from "../common"

const StyledMobileNav = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${props => props.theme.colors.buttonBg};
  color: #ffffff;
  z-index: 9999;
  padding: 2rem ${props => props.theme.padding.hMobile};
  > nav {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  ${NavLink} {
    font-size: 30px;
    margin: 0.5rem 0;
    &.active {
      text-decoration: underline;
    }
  }
`

const AnimatedNav = animated(StyledMobileNav)
const AnimatedNavLink = animated(NavLink)

export const MobileNav = ({ items, menuState, onClose }) => {
  const transRef = useRef()
  const transitions = useTransition(menuState, null, {
    ref: transRef,
    config: config.default,
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })
  // Trail
  const trailRef = useRef()
  const trail = useTrail(items.length, {
    ref: trailRef,
    config: config.default,
    opacity: menuState ? 1 : 0,
    x: menuState ? 0 : 100,
    from: { opacity: 0, x: 100 },
  })

  useChain(menuState ? [transRef, trailRef] : [trailRef, transRef], [0, 1])

  return transitions.map(
    ({ item, key, props }) =>
      item && (
        <AnimatedNav key={key} style={props}>
          <nav>
            {trail.map(({ x, ...rest }, index) => (
              <AnimatedNavLink
                key={items[index]}
                to={items[index].page.url}
                onClick={onClose}
                activeClassName="active"
                style={{
                  ...rest,
                  transform: x.interpolate(x => `translate3d(-${x}px,0,0)`),
                }}
              >
                {items[index].nav_title}
              </AnimatedNavLink>
            ))}
          </nav>
        </AnimatedNav>
      )
  )
}
