import styled from "styled-components"

export const FlexBox = styled.div`
  display: flex;
  flex-direction: ${props => props.direction || "row"};
  flex-wrap: ${props => props.wrap || "wrap"};
  align-items: ${props => props.alignItems || "center"};
  justify-content: ${props => props.justifyContent || "space-between"};
`

export const StyledImageText = styled.section`
  max-width: ${props => props.theme.containerWidths.standard};
  margin: auto;
  > div:nth-child(2) {
    padding: 2rem ${props => props.theme.padding.hMobile};
  }
  button,
  a {
    display: inline-block;
    margin-top: 1rem;
  }
  ul {
    padding-left: 20px;
    li {
      margin-bottom: 0.5em;
    }
  }

  @media ${props => props.theme.breakpoints.medium} {
    padding: 0 ${props => props.theme.padding.hDesk};
    ul {
      padding-left: 20px;
      margin-top: 1rem;
      columns: 2;
      column-gap: 1rem;
    }
  }
  @media ${props => props.theme.breakpoints.large} {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    align-items: center;
    img,
    .gatsby-image-wrapper {
      grid-column: ${props => (props.reverse ? "7 / span 6" : "1 / span 6")};
      grid-row: 1;
    }
    > div:nth-child(2) {
      padding: 0;
      grid-column: ${props => (props.reverse ? "1 / span 5" : "8 / span 5")};
    }
  }
`
