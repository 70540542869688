export const siteTheme = {
  breakpoints: {
    ns: "screen and (min-width: 30em)",
    medium: "screen and (min-width: 30em)",
    large: "screen and (min-width: 64em)",
    xl: "screen and (min-width: 94em)",
  },
  colors: {
    docBg: "#F7FFFA",
    textColor: "#0000000",
    pullColor: "#2AC17F",
    buttonBg: "#2AC17F",
    buttonText: "#ffffff",
    subButtonBg: "#F7FFFA",
    subButtonText: "#2AC17F",
    footerBg: "#000000",
    footerText: "#D4F0DC",
  },
  fonts: {
    regular: "axiformaregular",
    heading: "axiformabook",
    medium: "axiformamedium",
    bold: "axiformabold",
  },
  fontSizes: {
    bodyReg: "16px",
    h1: "calc(40px + 44*(100vw - 320px)/2180)",
    h2: "calc(24px + 20*(100vw - 320px)/2180)",
    h3: "calc(22px + 15*(100vw - 320px)/2180)",
    h4: "calc(20px + 5*(100vw - 320px)/2180)",
    h5: "20px",
  },
  lineHeights: {
    standard: "1.8",
    heading: "1.4",
  },
  measures: {
    large: "55em",
    medium: "45em",
    standard: "30em",
    narrow: "20em",
  },
  padding: {
    hMobile: "1.5rem",
    hDesk: "6rem",
  },
  containerWidths: {
    standard: "1320px",
    medium: "1320px",
  },
  pageSpacers: {
    small: "20px",
    medium: "60px",
    large: "200px",
  },
}
