import React, { useState, useEffect } from "react"
import EmblaCarouselReact from "embla-carousel-react"
import styled from "styled-components"

import ArrowLeft from "../../svg/arrow-left.svg"
import ArrowRight from "../../svg/arrow-right.svg"

const StyledCarouselCanvas = styled.div`
  display: flex;
  align-items: center;
  cursor: grab;
`

const StyledCardCarousel = styled.div`
  padding: 0 ${props => props.theme.padding.hMobile};
  max-width: ${props => props.theme.containerWidths.standard};
  margin: auto;
  overflow: visible;
  header {
    margin-bottom: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    > nav {
      button {
        width: 3rem;
        &:last-child {
          margin-left: 2rem;
        }
      }
    }
  }

  > div:nth-child(2) {
    overflow: visible !important;
  }
  @media ${props => props.theme.breakpoints.medium} {
    padding: 0 ${props => props.theme.padding.hDesk};
  }
`

export const CardCarousel = ({ children, heading, ...props }) => {
  const [embla, setEmbla] = useState(null)
  const [prev, setPrev] = useState(false)
  useEffect(() => {
    if (embla) {
      embla.on("select", () => {
        if (embla.canScrollPrev()) {
          setPrev(true)
        } else {
          setPrev(false)
        }
      })
    }
  }, [embla])
  return (
    <StyledCardCarousel {...props}>
      <header>
        <h2>{heading.text}</h2>
        <nav>
          {prev && (
            <button onClick={() => embla.scrollPrev()}>
              <ArrowLeft />
            </button>
          )}
          <button onClick={() => embla.scrollNext()}>
            <ArrowRight />
          </button>
        </nav>
      </header>
      <EmblaCarouselReact
        emblaRef={setEmbla}
        options={{
          loop: false,
          startIndex: 0,
          containScroll: true,
          dragFree: true,
        }}
      >
        <StyledCarouselCanvas>{children}</StyledCarouselCanvas>
      </EmblaCarouselReact>
    </StyledCardCarousel>
  )
}
