import React, { useState, useContext } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Headroom from "react-headroom"
import styled from "styled-components"
import { BraveHamburger } from "./"
import { DeskNav, MobileNav } from "./"
import { SiteContext } from "../../context"

import Logo from "../../svg/logo.svg"

const StyledHeader = styled(props => <Headroom {...props} />)`
  z-index: 99999 !important;
  position: relative;
  ${props => props.modalOpen && `pointer-events: none;`}
  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    z-index: 999;
    padding: 2rem ${props => props.theme.padding.hMobile};
    transition: all 0.5s ease;
    opacity: 1;
    .logo {
      width: 140px;
      color: #fff;
      svg {
        fill: #fff;
      }
    }
    .b-hamburger {
      color: #fff;
    }
    @media ${props => props.theme.breakpoints.medium} {
      .logo {
        width: 180px;
      }
    }
    @media ${props => props.theme.breakpoints.large} {
      > button {
        display: none;
      }
      padding: 2rem 3rem;
    }
    ${props => props.modalOpen && `opacity:0; pointer-events: none;`}
  }
  .headroom--pinned {
    > header {
      background: ${props => props.theme.colors.docBg};

      color: ${props => props.theme.colors.pullColor};
      .b-hamburger {
        color: ${props => props.theme.colors.pullColor};
      }
      .logo {
        svg {
          fill: ${props => props.theme.colors.pullColor};
        }
      }
    }
  }
`

export const Header = () => {
  const [menuState, setMenuState] = useState(false)
  const { modalOpen } = useContext(SiteContext)
  const { prismicGlobals } = useStaticQuery(graphql`
    query navQuery {
      prismicGlobals {
        data {
          nav {
            nav_title
            button_display
            page {
              url
            }
          }
        }
      }
    }
  `)
  return (
    <>
      <StyledHeader modalOpen={modalOpen}>
        <header>
          <Link to="/" className="logo">
            <Logo />
          </Link>
          <DeskNav items={prismicGlobals.data.nav} />
          <button onClick={() => setMenuState(!menuState)}>
            <BraveHamburger menuState={menuState} />
          </button>
        </header>
      </StyledHeader>
      <MobileNav
        items={prismicGlobals.data.nav}
        menuState={menuState}
        onClose={() => setMenuState(false)}
      />
    </>
  )
}
