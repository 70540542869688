import React from "react"
import PropTypes from "prop-types"
import styled, { ThemeProvider } from "styled-components"
import SEO from "../components/seo"
import "../css/fonts.css"
import { siteTheme } from "../themes/theme.js"
import { GlobalStyles } from "../css/globalStyles"
import { Header } from "../components/nav"

const StyledPageWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
`

const Layout = ({ children, location }) => {
  return (
    <ThemeProvider theme={siteTheme}>
      <SEO
        title="Directus NZ"
        description="Directus supplies a wide range of food and beverage ingredients, finished products and process and pack options to companies around the world."
      />
      <GlobalStyles />
      <Header />
      <StyledPageWrapper>{children}</StyledPageWrapper>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
