import styled from "styled-components"

export const BodyCopy = styled.div`
  font-size: ${props => props.theme.fontSizes.bodyReg};
  line-height: ${props => props.theme.lineHeights.standard};
  color: inherit;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0.6rem 0;
  }
  ${props => props.center && "text-align: center"}
`
