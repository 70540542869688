import React from "react"
import styled from "styled-components"
import { useSpring, config, animated } from "react-spring"
import CloseIcon from "../../svg/close.svg"

import useFormValidation from "../../hooks/useFormValidation"

import {
  BodyCopy,
  StyledInput,
  StyledButton,
  StyledTextArea,
  FlexBox,
} from "../common"

const StyledFormModal = styled.div`
  position: fixed;

  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 0rem;
  background: rgba(0, 0, 0, 0.6);
  z-index: 99999;
  overflow-y: scroll;
  > form {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    background: #fff;
    position: relative;
    z-index: 20;
    padding: 2rem ${props => props.theme.padding.hMobile};
    > ${BodyCopy} {
      max-width: ${props => props.theme.measures.medium};
      text-align: center;
      margin: 0 auto 0 auto;
      h2,
      h3 {
        color: ${props => props.theme.colors.pullColor};
      }
    }
    > ${FlexBox} {
      margin-top: 1.5rem;
      > input {
        width: 100%;
      }
    }
    ${StyledTextArea} {
      width: 100%;
    }
    ${StyledButton} {
      margin-left: auto;
      display: flex;
    }
  }
  @media ${props => props.theme.breakpoints.medium} {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${props => props.theme.padding.hMobile};
    > form {
      padding: 2rem ${props => props.theme.padding.hDesk};
      > ${FlexBox} {
        > input {
          width: calc(50% - 1rem);
        }
      }
    }
  }
`

const InvisibleButtonBG = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0%;
  background: transparent;
  display: block;
  cursor: pointer;
  z-index: 1;
`
const ModalCloseButton = styled.div`
  width: 1.8rem;
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  > svg {
    fill: ${props => props.theme.colors.pullColor};
  }
`

const AnimatedFormModal = animated(StyledFormModal)

const INITIAL_STATE = {}

export const PopupForm = ({ isOpen, onClose, form_name, text }) => {
  const { handleSubmit, handleChange, success } = useFormValidation(
    INITIAL_STATE
  )
  const { x, y } = useSpring({
    x: isOpen ? 1 : 0,
    y: isOpen ? 0 : 10,
    z: isOpen ? 0 : 1,
    config: config.gentle,
  })
  return (
    <AnimatedFormModal
      style={{
        opacity: x.interpolate(x => x),
        pointerEvents: isOpen ? "all" : "none",
      }}
    >
      <InvisibleButtonBG onClick={onClose} />
      <animated.form
        name={form_name}
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        subject={`Directus - ${form_name}`}
        onSubmit={handleSubmit}
        style={{
          transform: y.interpolate(y => `translate3d(0,${y}%, 0)`),
        }}
      >
        <input type="hidden" name="form-name" value={form_name} />
        <p hidden>
          <label>
            <input name="bot-field" onChange={handleChange} />
          </label>
        </p>
        <ModalCloseButton onClick={onClose}>
          <CloseIcon />
        </ModalCloseButton>
        {success ? (
          <>
            <BodyCopy center>
              <h3>Thank You</h3>
              <p>One of our team members will contact you shortly.</p>
            </BodyCopy>
          </>
        ) : (
          <>
            <BodyCopy>
              <h3>{form_name}</h3>
              <div dangerouslySetInnerHTML={{ __html: text.html }} />
            </BodyCopy>
            <FlexBox wrap="wrap">
              <StyledInput
                placeholder="First Name"
                type="text"
                name="firstName"
                onChange={handleChange}
              />
              <StyledInput
                placeholder="Last Name"
                name="lastName"
                onChange={handleChange}
              />
              <StyledInput
                placeholder="Email Addess"
                name="email"
                onChange={handleChange}
              />
              <StyledInput
                placeholder="Company Name"
                name="company"
                onChange={handleChange}
              />
            </FlexBox>
            <StyledTextArea
              placeholder="Your message"
              rows={4}
              name="message"
              onChange={handleChange}
            />
            <StyledButton type="submit" long>
              Submit
            </StyledButton>
          </>
        )}
      </animated.form>
    </AnimatedFormModal>
  )
}
