import React from "react"
import styled from "styled-components"

import { Link } from "gatsby"

export const ButtonLink = styled(props => <Link {...props} />)`
  background-color: ${props => props.theme.colors.buttonBg};
  color: ${props => props.theme.colors.buttonText};
  font-size: ${props => props.theme.fontSizes.bodyReg};
  text-decoration: none;
  padding: 0.8rem 2rem;
  border-radius: 100px;
  > svg {
    fill: ${props => props.theme.colors.buttonText};
    width: 1rem;
    margin-left: 1rem;
  }
`

export const StyledButton = styled.button`
  background-color: ${props => props.theme.colors.buttonBg};
  color: ${props => props.theme.colors.buttonText};
  font-size: ${props => props.theme.fontSizes.bodyReg};
  text-decoration: none;
  border-radius: 100px;
  ${props => (props.long ? `padding: 0.8rem 3rem;` : `padding: 0.8rem 2rem;`)}
`

export const NavLink = styled(props => <Link {...props} />)`
  color: ${props => props.theme.colors.textColor};
  font-size: ${props => props.theme.fontSizes.bodyRegular};
  text-decoration: none;
  color: inherit;
  text-decoration: none;
  &.active {
    opacity: 1;
  }
`

export const StyledInput = styled.input`
  color: #969696;
  border: 1px solid #d8d8d8;
  background: #fff;
  padding: 1rem;
  margin: 1rem 0;
  font-size: ${props => props.theme.fontSizes.bodyReg};
  display: block;
  border-radius: 2px;
  &:focus {
    outline: none;
  }
`

export const StyledTextArea = styled.textarea`
  border: 1px solid #d8d8d8;
  color: #969696;
  font-size: ${props => props.theme.fontSizes.bodyReg};
  padding: 1rem;
  margin: 1rem 0;
  resize: none;
  border-radius: 2px;
  &:focus {
    outline: none;
  }
`

export const StyledSelect = styled.div`
  position: relative;
  margin: 1rem 0;
  > select {
    border: 1px solid #d8d8d8;
    color: #969696;
    font-size: ${props => props.theme.fontSizes.bodyReg};
    background: #fff;
    border-radius: 2px;
    padding: 1rem 2rem 1rem 1rem;
    width: 100%;
    -webkit-appearance: none;
  }
  > svg {
    width: 1.2rem;
    position: absolute;
    top: 0;
    right: 0.5rem;
    height: 100%;
    pointer-events: none;
    fill: #aeaeae;
  }
`
