import React, { createContext, useState } from "react"

const defaultValues = {
  modalOpen: false,
  toggleModalOpen: () => {},
}

export const SiteContext = createContext(defaultValues)

export const SiteProvider = ({ children }) => {
  const [modalOpen, setModalOpen] = useState(defaultValues.modalOpen)
  const toggleModalOpen = () => setModalOpen(!modalOpen)

  return (
    <SiteContext.Provider
      value={{
        ...defaultValues,
        modalOpen,
        toggleModalOpen,
      }}
    >
      {children}
    </SiteContext.Provider>
  )
}
