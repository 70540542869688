import React from "react"
import styled from "styled-components"

import { NavLink, ButtonLink } from "../common"

const StyledNav = styled.nav`
  display: none;
  color: inherit;
  ${NavLink}, ${ButtonLink} {
    margin-left: 1.5rem;
  }
  ${NavLink} {
    &.active {
      text-decoration: underline;
    }
  }
  @media ${props => props.theme.breakpoints.large} {
    display: flex;
    align-items: center;
  }
`

export const DeskNav = ({ items }) => {
  return (
    <StyledNav>
      {items.map(item => {
        const { page, nav_title, button_display } = item
        if (button_display === "true") {
          return <ButtonLink to={page.url}>{nav_title}</ButtonLink>
        } else {
          return (
            <NavLink to={`${page.url}/`} activeClassName="active">
              {nav_title}
            </NavLink>
          )
        }
      })}
    </StyledNav>
  )
}
