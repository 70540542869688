import React, { useState } from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import { useSpring, config, animated } from "react-spring"
import useMeasure from "react-use-measure"
import { FlexBox, BodyCopy } from "../common"
import polyfill from "@juggle/resize-observer"

import GlobeIcon from "../../svg/globe.svg"
import ChevronIcon from "../../svg/chevron.svg"

const StyledFooter = styled.footer`
  padding: 3rem ${props => props.theme.padding.hMobile};
  background: ${props => props.theme.colors.footerBg};
  color: ${props => props.theme.colors.footerText};

  @media ${props => props.theme.breakpoints.medium} {
    padding: 5rem 0;
  }
`

const FooterQuote = styled(BodyCopy)`
  font-size: ${props => props.theme.fontSizes.h3};
`

const FooterInner = styled.div`
  max-width: ${props => props.theme.containerWidths.standard};
  margin: auto;
  @media ${props => props.theme.breakpoints.medium} {
    padding: 0 ${props => props.theme.padding.hDesk};
  }
`
const FooterSectionsMobile = styled.div`
  margin-top: 2rem;
  display: block;
  @media ${props => props.theme.breakpoints.medium} {
    display: none;
  }
`
const FooterSectionsDesktop = styled.div`
  display: none;
  @media ${props => props.theme.breakpoints.medium} {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 3rem;
    justify-content: space-around;
    align-content: space-between;
  }
`
const StyledFooterSection = styled(FlexBox)`
  width: 100%;
  align-items: flex-start;
  grid-column: auto / span 4;
  > ${BodyCopy} {
    width: 100%;
    div {
      border-top: 1px solid ${props => props.theme.colors.footerText};
    }
  }
  h4 {
    padding: 1rem 0;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    svg {
      fill: ${props => props.theme.colors.footerText};
      width: 1rem;
    }
  }

  @media ${props => props.theme.breakpoints.large} {
    grid-column: auto / span 3;
  }
`

const FooterGlobe = styled.div`
  margin-bottom: 1rem;
  grid-column: auto / span 3;
  display: none;
  > svg {
    width: 4rem;
    fill: ${props => props.theme.colors.footerText};
  }
  @media ${props => props.theme.breakpoints.large} {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
`

const FooterBottom = styled.div`
  text-align: center;
  padding: 2rem 0;
  > a {
    color: inherit;
    text-decoration: none;
    margin-top: 1rem;
    display: block;
    font-family: Georgia, "Times New Roman", Times, serif;
    font-style: italic;
  }
  @media ${props => props.theme.breakpoints.medium} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid ${props => props.theme.colors.footerText};
    margin-top: 2rem;
    > a {
      margin-top: 0;
    }
  }
`
const FooterSection = ({ heading, text }) => {
  const [isOpen, setOpen] = useState(false)
  const [ref, measure] = useMeasure({ polyfill })

  const { x, y, z } = useSpring({
    x: isOpen ? measure.height : 0,
    y: isOpen ? 1 : 0,
    z: isOpen ? 180 : 0,
    config: config.gentle,
  })
  return (
    <StyledFooterSection>
      <BodyCopy>
        <h4 onClick={() => setOpen(!isOpen)}>
          {heading.text}{" "}
          <animated.div
            style={{
              transform: z.interpolate(z => `rotate(${z}deg)`),
            }}
          >
            <ChevronIcon />
          </animated.div>
        </h4>
        <animated.div
          style={{
            height: x,
            opacity: y.interpolate(y => y),
            overflow: "hidden",
          }}
        >
          <div
            dangerouslySetInnerHTML={{ __html: text.html }}
            ref={ref}
            style={{ padding: "0.5rem 0" }}
          />
        </animated.div>
      </BodyCopy>
    </StyledFooterSection>
  )
}

export const Footer = () => {
  const { prismicGlobals } = useStaticQuery(graphql`
    query {
      prismicGlobals {
        data {
          footer_quote {
            html
          }
          footer_section {
            heading {
              text
            }
            text {
              html
            }
          }
        }
      }
    }
  `)
  const { footer_quote, footer_section } = prismicGlobals.data
  return (
    <StyledFooter>
      <FooterInner>
        <FooterQuote dangerouslySetInnerHTML={{ __html: footer_quote.html }} />
        <FooterSectionsMobile>
          {footer_section.map(section => (
            <FooterSection {...section} />
          ))}
          <FooterGlobe>
            <GlobeIcon />
          </FooterGlobe>
        </FooterSectionsMobile>
        <FooterSectionsDesktop>
          {footer_section.map(section => (
            <StyledFooterSection>
              <BodyCopy>
                <h4>{section.heading.text}</h4>
              </BodyCopy>
            </StyledFooterSection>
          ))}
        </FooterSectionsDesktop>
        <FooterSectionsDesktop>
          {footer_section.map(section => (
            <StyledFooterSection>
              <BodyCopy>
                <div dangerouslySetInnerHTML={{ __html: section.text.html }} />
              </BodyCopy>
            </StyledFooterSection>
          ))}
        </FooterSectionsDesktop>
        <FooterBottom>
          <p>Copyright Directus 2019. All rights reserved.</p>
        </FooterBottom>
      </FooterInner>
    </StyledFooter>
  )
}
